


























export default
  name: 'App'
  components: {}
  data: ->
    menuItems: [
      {title: 'Главная', icon: 'fal fa-fw fa-home', path: '/'}
      {title: 'Вопросы и ответы', icon: 'fal fa-fw fa-question-circle', path: '/about'}
      {title: 'Платежная форма', icon: 'fal fa-fw fa-clipboard-list-check', path: '/form'}
      {title: 'Реализованные проекты', icon: 'fal fa-fw fa-archive', path: '/archive'}
    ]
    drawer: false,
  watch:
    '$route': (to, from) ->
        document.title = to.meta.title || 'Дубровские зори'
