import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {title: 'Дубровские зори'}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/about.vue'),
    meta: {title: 'Как это работает'}
  },
  {
    path: '/form',
    name: 'payment_form',
    component: () => import('@/views/payment_form.vue'),
    meta: {title: 'Платежная форма'}
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/list.vue'),
    meta: {title: 'Список'}
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('@/views/archive.vue'),
    meta: {title: 'Реализованные проекты'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
