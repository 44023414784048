import '@fortawesome/fontawesome-pro/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      cancel: 'fal fa-ban',
      menu: 'far fa-ellipsis-v',
    },
  },
});
