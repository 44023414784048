















import Vuex from 'vuex'
export default
  name: 'house-dots'
  data: ->
    loading: true
    items: []
  computed: Object.assign(
    paid: -> Math.ceil(@countPaid / @houses.length * 100),
    Vuex.mapState(['api_url', 'houses', 'amount', 'target', 'spent']),
    Vuex.mapGetters(['countPaid', 'totalAmount'])
  )
  mounted: ->
    @loadList()
  filters:
    roubles: (number) ->
      formatter = new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB', maximumFractionDigits: 0, minimumFractionDigits: 0})
      formatter.format(number)
  methods:
    updatePaid: ->
      for i in @items
        house_number = i.list_fields['other.house']
        @$store.dispatch('setPaid', house_number)
    loadList: ->
      @$set(@, 'loading', true)
      @axios.get(@api_url)
        .then (res) =>
          @$set(@, 'items', res.data.race.heats)
          @updatePaid()
          @$set(@, 'loading', false)
        .catch (e) =>
          console.log(e)
          @$set(@, 'loading', false)
