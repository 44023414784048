

































import HouseDots from '@/components/house-dots'
export default
  name: 'home'
  components: {HouseDots}
  computed:
    registration_open: -> @$store.state.registration_open
    budget: -> @$store.state.target
  filters:
    roubles: (number) ->
      formatter = new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB', maximumFractionDigits: 0, minimumFractionDigits: 0})
      formatter.format(number)

